<template>
  <div class="hold-transition">
    <div
      class="modal fade"
      id="modal-form-detalle-tepautorizacion"
      style="background-color: #00000082"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Detalle Autorización</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-0">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="tab-Autorizacion"
                  data-toggle="tab"
                  href="#Autorizacion"
                  >Autorización</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="tab-Solicitud"
                  data-toggle="tab"
                  href="#Solicitud"
                  >Solicitud
                </a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade active show" id="Autorizacion">
                <div class="card-body">
                  <!-- Detalle de la Autorizacion -->
                  <div class="row">
                    <div class="col-md-12">
                      <div
                        class="position-relative p-3 m-2 bg-light"
                        style="font-size: 0.85em"
                      >
                        <div class="ribbon-wrapper ribbon-lg">
                          <div
                            class="ribbon"
                            :class="
                              data_autorizacion.estado == 1
                                ? 'badge-success'
                                : data_autorizacion.estado == 2
                                ? 'badge-warning'
                                : data_autorizacion.estado == 3
                                ? 'badge-danger'
                                : 'badge-secondary'
                            "
                          >
                            <small>{{ data_autorizacion.nEstado }}</small>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-2">
                            <div class="description-block">
                              <h5 class="description-header">
                                {{ data_autorizacion.id }}
                              </h5>
                              <span class="description-text text-secondary"
                                ># Autorización</span
                              >
                            </div>
                          </div>
                          <div class="col-sm-3">
                            <div class="description-block">
                              <h5 class="description-header">
                                {{
                                  data_autorizacion.cs_solicitud_type ==
                                  `App\\CsSolicitudNacional`
                                    ? "Nacional"
                                    : data_autorizacion.cs_solicitud_type ==
                                      `App\\CsSolicitudInterna`
                                    ? "Interna"
                                    : "Sin establecer"
                                }}
                              </h5>
                              <span class="description-text text-secondary"
                                >Tipo Solicitud</span
                              >
                            </div>
                          </div>
                          <div class="col-sm-4">
                            <div class="description-block">
                              <h5 class="description-header">
                                {{
                                  data_autorizacion.user
                                    ? data_autorizacion.user.name
                                    : ""
                                }}
                              </h5>
                              <span class="description-text text-secondary"
                                >Usuario</span
                              >
                            </div>
                          </div>
                          <div class="col-sm-2">
                            <div class="description-block">
                              <h5 class="description-header">
                                {{ data_autorizacion.nTipoAutorizacion }}
                              </h5>
                              <span class="description-text text-secondary"
                                >Tipo Autorización</span
                              >
                            </div>
                          </div>
                          <div
                            class="col-sm-3"
                            v-if="data_autorizacion.fecha_autorizacion"
                          >
                            <div class="description-block">
                              <h5 class="description-header">
                                {{ data_autorizacion.fecha_autorizacion }}
                              </h5>
                              <span class="description-text text-secondary"
                                >Fecha Autorización</span
                              >
                            </div>
                          </div>
                          <div
                            class="col-sm-12"
                            v-if="data_autorizacion.observaciones"
                          >
                            <div class="description-block">
                              <h5 class="description-header">
                                <span>
                                  {{ data_autorizacion.observaciones }}</span
                                >
                              </h5>
                              <span class="description-text text-secondary"
                                >Observaciones</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane"
                id="Solicitud"
                v-if="data_autorizacion.cs_solicitud"
              >
                <div class="card-body">
                  <div class="row justify-content-md-center">
                    <!-- Detalle solicitud -->
                    <div class="col-md-12">
                      <div
                        class="position-relative bg-light mb-3"
                        style="font-size: 0.85em"
                      >
                        <div class="ribbon-wrapper ribbon-lg">
                          <div
                            class="ribbon"
                            :class="
                              data_autorizacion.cs_solicitud.estado == 1
                                ? 'bg-info'
                                : data_autorizacion.cs_solicitud.estado == 2
                                ? 'bg-success'
                                : data_autorizacion.cs_solicitud.estado == 3
                                ? 'bg-danger'
                                : 'bg-navy'
                            "
                          >
                            <small>{{
                              data_autorizacion.cs_solicitud.nEstado
                            }}</small>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-1 text-center p-2">
                            <a
                              class="btn btn-app btn-sm bg-success"
                              :href="
                                uri_docs +
                                data_autorizacion.cs_solicitud
                                  .archivo_emergencia
                              "
                              target="_blank"
                              v-if="
                                data_autorizacion.cs_solicitud.tipo_solicitud ==
                                2
                              "
                            >
                              <i class="fas fa-download"></i>
                              <small>Descargar</small>
                            </a>
                          </div>
                          <div class="col-sm-2">
                            <div class="description-block">
                              <h5 class="description-header">
                                {{ data_autorizacion.cs_solicitud.id }}
                              </h5>
                              <span class="description-text text-secondary"
                                ># Solicitud</span
                              >
                            </div>
                          </div>
                          <div class="col-sm-2">
                            <div class="description-block">
                              <h5 class="description-header">
                                {{ data_autorizacion.cs_solicitud.fecha }}
                              </h5>
                              <span class="description-text text-secondary"
                                >Fecha Solicitud</span
                              >
                            </div>
                          </div>
                          <div class="col-sm-3">
                            <div class="description-block">
                              <h5 class="description-header">
                                {{ data_autorizacion.cs_solicitud.user.name }}
                              </h5>
                              <span class="description-text text-secondary"
                                >Usuario</span
                              >
                            </div>
                          </div>
                          <div class="col-sm-3">
                            <div class="description-block">
                              <h5 class="description-header">
                                {{
                                  data_autorizacion.cs_solicitud.nTipoSolicitud
                                }}
                              </h5>
                              <span class="description-text text-secondary"
                                >Tipo Solicitud</span
                              >
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-4">
                            <div class="description-block">
                              <h5 class="description-header">
                                {{
                                  data_autorizacion.cs_solicitud_presupuesto
                                    ? data_autorizacion.cs_solicitud_presupuesto
                                        .nTipoCargo
                                    : ""
                                }}
                              </h5>
                              <span class="description-text text-secondary"
                                >Tipo Cargo</span
                              >
                            </div>
                          </div>
                          <div class="col-sm-4">
                            <div class="description-block">
                              <h5 class="description-header">
                                {{
                                  data_autorizacion.cs_solicitud_presupuesto
                                    ? data_autorizacion.cs_solicitud_presupuesto
                                        .ceco_afe.codigo_contable
                                    : ""
                                }}
                                <span
                                  class="badge"
                                  :class="
                                    data_autorizacion.cs_solicitud_presupuesto
                                      .tipo_presupuesto == 1
                                      ? 'bg-success'
                                      : 'bg-navy'
                                  "
                                  v-if="
                                    data_autorizacion.cs_solicitud_presupuesto
                                  "
                                >
                                  {{
                                    data_autorizacion.cs_solicitud_presupuesto
                                      .tipo_presupuesto == 1
                                      ? "AFE"
                                      : "CECO"
                                  }}
                                </span>
                              </h5>
                              <span class="description-text text-secondary"
                                >CECO / AFE</span
                              >
                            </div>
                          </div>
                          <div
                            class="col-sm-4"
                            v-if="
                              data_autorizacion.cs_solicitud_type ==
                              'App\\CsSolicitudNacional'
                            "
                          >
                            <div class="description-block">
                              <h5 class="description-header">
                                {{
                                  data_autorizacion.cs_solicitud_presupuesto
                                    ? data_autorizacion.cs_solicitud_presupuesto
                                        .nCentroLogistico
                                    : ""
                                }}
                              </h5>
                              <span class="description-text text-secondary"
                                >Centro Logístico</span
                              >
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-6">
                            <div class="description-block">
                              <h5 class="description-header">
                                {{
                                  data_autorizacion.cs_solicitud.justificacion
                                }}
                              </h5>
                              <span class="description-text text-secondary"
                                >Justificación</span
                              >
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="description-block">
                              <h5 class="description-header">
                                {{
                                  data_autorizacion.cs_solicitud.observaciones
                                }}
                              </h5>
                              <span class="description-text text-secondary"
                                >Observaciones</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Tipos de Vehículo -->
                    <div
                      class="col-md-12"
                      v-if="
                        data_autorizacion.cs_solicitud_type ==
                        'App\\CsSolicitudNacional'
                      "
                    >
                      <div class="card card-dark">
                        <div class="card-header">
                          <h3 class="card-title text-bold">Tipo de Vehículo</h3>
                        </div>
                        <div class="card-body table-responsive">
                          <div class="row">
                            <div class="col-md-12">
                              <table
                                class="
                                  table table-bordered table-striped table-hover
                                  text-nowrap
                                  table-sm
                                  mb-0
                                "
                              >
                                <thead class="bg-gray text-center">
                                  <tr>
                                    <th>Tipo Servicio</th>
                                    <th>Tipo Vehículo</th>
                                    <th>Cantidad</th>
                                    <th>Tiempo <small>(días)</small></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    class="text-center"
                                    v-for="item in data_autorizacion
                                      .cs_solicitud.tipos_servicios"
                                    :key="item.id"
                                  >
                                    <td>
                                      <span
                                        class="badge"
                                        :class="
                                          item.tipo_servicio == 1
                                            ? 'bg-info'
                                            : item.tipo_servicio == 2
                                            ? 'bg-navy'
                                            : 'badge-secondary'
                                        "
                                        >{{
                                          item.nTipoServicio
                                            ? item.nTipoServicio
                                            : "Sin definir"
                                        }}
                                      </span>
                                    </td>
                                    <td
                                      class="text-left"
                                      :class="
                                        item.tipo_servicio != 1 &&
                                        item.tipo_servicio != 2
                                          ? 'text-muted'
                                          : ''
                                      "
                                    >
                                      {{
                                        item.tipo_servicio == 1
                                          ? item.nTipoVehiculo
                                          : item.tipo_servicio == 2
                                          ? item.nEquipo
                                          : `Sin definir`
                                      }}
                                    </td>
                                    <td>{{ item.cantidad }}</td>
                                    <td>{{ item.tiempo }}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Ruta -->
                    <div class="col-md-12">
                      <div class="card card-dark">
                        <div class="card-header">
                          <h3 class="card-title text-bold">Ruta</h3>
                        </div>
                        <div class="card-body table-responsive">
                          <div class="row">
                            <div class="col-md-12">
                              <table
                                class="
                                  table table-bordered table-striped table-hover
                                  text-nowrap
                                  table-sm
                                  mb-0
                                "
                              >
                                <thead class="bg-gray text-center">
                                  <tr>
                                    <th>Tipo Sitio</th>
                                    <th>Sitio</th>
                                    <th>Dirección</th>
                                    <th>Contacto</th>
                                    <th>Teléfono</th>
                                    <th>Email</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="item in data_autorizacion.cs_solicitud.rutas" :key="item.id">
                                    <td class="text-center">
                                      <span
                                        class="badge"
                                        :class="
                                          item.tipo_sitio == 1
                                            ? 'bg-info'
                                            : item.tipo_sitio == 2
                                            ? 'bg-navy'
                                            : 'badge-secondary'
                                        "
                                        >{{ item.nTipoSitio }}
                                      </span>
                                    </td>
                                    <td>{{ item.nombre }}</td>
                                    <td>{{ item.direccion }}</td>
                                    <td>{{ item.nombre_contacto }}</td>
                                    <td>{{ item.telefono_contacto }}</td>
                                    <td>{{ item.email_contacto }}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Detalles de la Carga -->
                    <div
                      class="col-md-12"
                      v-if="
                        data_autorizacion.cs_solicitud_type ==
                        'App\\CsSolicitudNacional'
                      "
                    >
                      <div class="card card-dark">
                        <div class="card-header">
                          <h3 class="card-title text-bold">
                            Detalles de la carga
                          </h3>
                        </div>
                        <div class="card-body table-responsive">
                          <div class="row">
                            <div class="col-md-12">
                              <table
                                class="
                                  table table-bordered table-striped table-hover
                                  text-nowrap
                                  table-sm
                                  mb-0
                                "
                              >
                                <thead class="bg-gray text-center">
                                  <tr>
                                    <th># Pedido</th>
                                    <th>Pos. Pedido</th>
                                    <th>Material</th>
                                    <th>
                                      Dimensión
                                      <small>(largo x alto x ancho)</small>
                                    </th>
                                    <th>Peso</th>
                                    <th>Valor</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="item in data_autorizacion
                                      .cs_solicitud.detalles"
                                    :key="item.id"
                                  >
                                    <td class="text-center">
                                      {{ item.no_pedido_compra }}
                                    </td>
                                    <td class="text-center">
                                      {{ item.pos_pedido }}
                                    </td>
                                    <td>{{ item.descripcion_material }}</td>
                                    <td class="text-center">
                                      {{
                                        `${item.largo} x ${item.alto} x ${item.ancho}`
                                      }}
                                    </td>
                                    <td class="text-right">{{ item.peso }}</td>
                                    <td class="text-right">
                                      {{ `${item.valor} (${item.nCodMoneda})` }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Detalles de la Solicitud -->
                    <div
                      class="col-md-12"
                      v-if="
                        data_autorizacion.cs_solicitud_type ==
                        'App\\CsSolicitudInterna'
                      "
                    >
                      <div class="card card-dark">
                        <div class="card-header">
                          <h3 class="card-title text-bold">
                            Detalles de la Solicitud
                          </h3>
                        </div>
                        <div class="card-body table-responsive">
                          <div class="row">
                            <div class="col-md-12">
                              <table
                                class="
                                  table table-bordered table-striped table-hover
                                  text-nowrap
                                  table-sm
                                  mb-0
                                "
                              >
                                <thead class="bg-gray text-center">
                                  <tr>
                                    <th>Tipo Servicio</th>
                                    <th>Tipo Vehículo</th>
                                    <th>Cantidad</th>
                                    <th>Tiempo <small>(días)</small></th>
                                    <th>Descripcion Material</th>
                                    <th>Cantidad Material</th>
                                    <th>
                                      Dimensión
                                      <small>(largo x alto x ancho)</small>
                                    </th>
                                    <th>Peso</th>
                                    <th>Radio</th>
                                    <th>Observaciones</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="item in data_autorizacion
                                      .cs_solicitud.det_solicitudes"
                                    :key="item.id"
                                  >
                                    <td class="text-center">
                                      {{ item.nTipoServicio }}
                                    </td>
                                    <td class="text-center">
                                      {{ item.nTipoVehiculo }}
                                    </td>
                                    <td>{{ item.cantidad_vh_equi }}</td>
                                    <td>{{ item.tiempo_serv }}</td>
                                    <td>{{ item.descripcion_material }}</td>
                                    <td>{{ item.cantidad_material }}</td>
                                    <td class="text-center">
                                      {{
                                        `${item.largo} x ${item.alto} x ${item.ancho}`
                                      }}
                                    </td>
                                    <td class="text-right">{{ item.peso }}</td>
                                    <td>{{ item.radio_izaje }}</td>
                                    <td>{{ item.observaciones }}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="tab-pane" id="Solicitud">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12">
                      <h2 class="lead">
                        <b><span>No tiene una solicitud asignada</span></b>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-between"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CsAutorizacionDetalle",
  components: {},
  data() {
    return {
      data_autorizacion: {},
      autorizacion_id: null,
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },
  methods: {
    async llenar_modal(item) {
      this.data_autorizacion = item;
    },
  },
};
</script>
